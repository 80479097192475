import { useState, Fragment, lazy } from "react";
import { Row, Col, Drawer,Modal} from "antd";
import { CSSTransition } from "react-transition-group";
import { withTranslation } from "react-i18next";

import * as S from "./styles";

const SvgIcon = lazy(() => import("../../common/SvgIcon"));
const Button = lazy(() => import("../../common/Button"));

const ContactBlock = lazy(() => import("../../components/ContactBlock"));

const Header = ({ t }) => {
    const [isNavVisible] = useState(false);
    const [isSmallScreen] = useState(false);
    const [visible, setVisibility] = useState(false);
  
    const showDrawer = () => {
      setVisibility(!visible);
    };
  
    const onClose = () => {
      setVisibility(!visible);
    };
  
    const MenuItem = () => {
      const scrollTo = (id) => {
        const element = document.getElementById(id);
        element.scrollIntoView({
          behavior: "smooth",
        });
        setVisibility(false);
      };
      const [isModalVisible, setIsModalVisible] = useState(false);

      const showModal = () => {
        setIsModalVisible(true);
      };

      const handleOk = () => {
        setIsModalVisible(false);
      };

      const handleCancel = () => {
        setIsModalVisible(false);
      };
      return (
        <Fragment>
          {/* <S.CustomNavLinkSmall onClick={() => scrollTo("about")}>
            <S.Span>{t("About")}</S.Span>
          </S.CustomNavLinkSmall>
          <S.CustomNavLinkSmall onClick={() => scrollTo("mission")}>
            <S.Span>{t("Mission")}</S.Span>
          </S.CustomNavLinkSmall>
          <S.CustomNavLinkSmall onClick={() => scrollTo("product")}>
            <S.Span>{t("Product")}</S.Span>
          </S.CustomNavLinkSmall>
          */} 
            <S.Span>
              <Button onClick={showModal}>{t("Contact")}</Button>
            </S.Span>
            <Modal title="Formulaire de contact" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null} > 
              <ContactBlock/>
            </Modal>
        </Fragment>
      );
    };
  
    return (
      <S.Header>
        <S.Container>
          <Row type="flex" justify="space-between" gutter={20}>
            <S.LogoContainer to="/" aria-label="homepage">
              <SvgIcon src="LogosVectorises-6-Conjonction-bleu.png" width="234" height="64" />
            </S.LogoContainer>
            <S.NotHidden>
              <MenuItem />
            </S.NotHidden>
            <S.Burger onClick={showDrawer}>
              <S.Outline />
            </S.Burger>
          </Row>
          <CSSTransition
            in={!isSmallScreen || isNavVisible}
            timeout={350}
            classNames="NavAnimation"
            unmountOnExit
          >
            <Drawer closable={false} visible={visible} onClose={onClose}>
              <Col style={{ marginBottom: "2.5rem" }}>
                <S.Label onClick={onClose}>
                  <Col span={12}>
                    <S.Menu>Menu</S.Menu>
                  </Col>
                  <Col span={12}>
                    <S.Outline padding="true" />
                  </Col>
                </S.Label>
              </Col>
              <MenuItem />
            </Drawer>
          </CSSTransition>
        </S.Container>
      </S.Header>
    );
  };
  
  export default withTranslation()(Header);